// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-row {
  float: right;
}

.valve-tag * {
  float: left;
  font-weight: 900;
  color: #0B2D71;
}

.valve-summary-page-header {
  font-weight: 500;
  color: #0B2D71;
}

.from-revision-name {
  margin-bottom: 5%;
}

button {
  font-weight: bolder;
}

.header-table {
  width: 5%;
}

h6 {
  float: left;
}

.last-edited {
  float: right;
}

.title {
  font-size: medium;
  white-space: nowrap;
  padding: 5px 4px;
}

.content {
  font-size: medium;
  white-space: nowrap;
  padding: 5px 4px;
}

.used-in-table {
  width: 100%;
}

.headers {
  font-weight: bolder;
  padding-top: 40px;
  color: #0B2D71;
}

.titles {
  font-weight: bolder;
}

.short-description {
  padding-top: 15px;
  padding-bottom: 15px;
}

.short-pipe-class-description {
  padding-bottom: 15px;
}

.valve-details-mat-accordion {
  padding-bottom: 15px;
}

p {
  font-size: 16px;
}

.used-in-table a {
  color: #0066B2;
  cursor: pointer;
}

.details-label {
  width: 20%;
  padding: 4px;
  font-weight: bolder;
  color: #696B6D;
}

.details-value {
  width: 80%;
  padding: 4px;
}

.parameters-label {
  width: 22%;
  padding: 2px;
  font-weight: bolder;
  color: #696B6D;
}

.parameters-value {
  width: 78%;
  padding: 4px;
}

.parameters {
  padding: 5px 5px 10px 0px;
}

fa-icon {
  padding: 3px;
}

.back-button {
  margin-bottom: 2%;
  cursor: pointer;
  color: #0066b2;
  border-color: #0066b2;
  background-color: #fff;
  border-radius: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
