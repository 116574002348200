import { Component, OnInit, Input } from '@angular/core';
import { PressureTemperatureCellViewModel, PressureTemperatureTableViewModel } from '../../shared/models/autogenerated-piping';
@Component({
  selector: 'app-pressure-temp-table',
  templateUrl: './pressure-temp-table.component.html',
  styleUrls: ['./pressure-temp-table.component.scss'],
})
export class PressureTempTableComponent implements OnInit {
  @Input() presTempTables: PressureTemperatureTableViewModel[];
  @Input() pipeClasTemperatureUnits: string;
  @Input() pipeClassPressureUnits: string;

  sourceOrAdditionalNoteExists: boolean = false;

  ngOnInit(): void {
    this.sortTableCellsByTemp();
    this.sourceOrAdditionalNoteExists = this.setSourceOrAdditionalNoteExists();
  }

  // Sorts the PTT's cells by temperature in ascending order
  private sortTableCellsByTemp(): void {
    for (const table of this.presTempTables) {
      table.cells.sort((a, b) => {
        if (a.temperature > b.temperature) {
          return 1;
        }
        if (a.temperature < b.temperature) {
          return -1;
        }
        return 0;
      });
    }
  }

  private setSourceOrAdditionalNoteExists(): boolean {
    this.presTempTables.forEach(ptt => {
      ptt.relationNotes.forEach(rn => {
        if (rn.type === 'source' || rn.type === 'additional') {
          return true;
        }
      });
    });
    return false;
  }

  filterOnlySpecificPressureUnits(cellsToFilter: PressureTemperatureCellViewModel[], unit: string): PressureTemperatureCellViewModel[] {
    return cellsToFilter.filter(cell => cell.pressureUnit.toLowerCase() == unit.toLowerCase());
  }

  filterOnlySpecificTemperatureUnits(cellsToFilter: PressureTemperatureCellViewModel[], unit: string): PressureTemperatureCellViewModel[] {
    return cellsToFilter.filter(cell => cell.temperatureUnit.toLowerCase() == unit.toLowerCase());
  }

  openCommonNote(code) {
    alert('Details of Common Note with code: ' + code);
  }
}
