import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  combineLatest,
  throwError,
} from 'rxjs';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  SearchRequest,
  FilterableProperty,
  EsraPipingAPIClient,
  PipingSearchFilterModel,
  FilterType,
  SearchResultObject_1OfObject as SearchResultObject,
  PipingObjectType,
  PipingFilterCategory,
} from 'src/app/shared/models/autogenerated-piping';
import { TechnologyEnum } from 'src/app/shared/models/technology-enum';
import { PageEvent } from '@angular/material/paginator';
import { FilterDataService } from '../../services/filter.service';
import { RangeFilterHelper } from '../common/range-filter.helper';
import { PipingAndValvesNavigationService } from 'src/app/piping-valves/services/piping-and-valves-navigation.service';
import { CelsiusToFahrenheitPipe } from 'src/app/shared/pipes/celcius-to-fahrenheit.pipe';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { FilterablePropertyFactory } from 'src/app/shared/components/factory/filterable-property-factory.service';
import { MatPaginatorGoToComponent } from '../../../shared/components/mat-paginator-go-to/mat-paginator-go-to.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-piping-search',
  templateUrl: './piping-search.component.html',
  styleUrls: ['./piping-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PipingSearchComponent implements OnInit, OnDestroy {
  constructor(
    private esraPipingAPIClient: EsraPipingAPIClient,
    private filterDataService: FilterDataService,
    public rangeFilterHelper: RangeFilterHelper,
    public navigator: PipingAndValvesNavigationService,
    private snackBarService: SnackBarService,
    private cd: ChangeDetectorRef
  ) {}

  faSearch = faSearch;
  searchText = '';
  currentPage = 0;
  technology: TechnologyEnum = TechnologyEnum.PIPES;
  lastPage: number;
  INTMAXCSHARP = 2147483647;
  isFilterBeingRemoved: boolean;

  totalCount: number;
  pageSize = 10;

  // object types
  _both = PipingObjectType._0;
  _pipe = PipingObjectType._1;
  _valve = PipingObjectType._2;

  // filter categories
  _commonFilter = PipingFilterCategory._3;
  _pipeFilter = PipingFilterCategory._1;
  _valveFilter = PipingFilterCategory._2;

  // input ids
  _pipesCheckBoxId = 'pipes-check-button';
  _valvesCheckBoxId = 'valves-check-button';

  // temperature units
  _fahrenheit = 'fahrenheit';
  _celsius = 'celcius';

  // comparison units
  _equals = 'equals (eq)';
  _greaterThan = 'greater than (gt)';
  _greaterThanEquals = 'greater or equals (ge)';
  _lessThan = 'less than (lt)';
  _lessThanEquals = 'less than or equals (le)';

  _defaultNominalCorrosionAllowanceComparisonSelectionString = this._equals;
  _defualtMaximumTemperatureComparisonSelectionString = this._greaterThanEquals;
  _defualtMinimumTemperatureComparisonSelectionString = this._lessThanEquals;
  nominalCorrosionAllowanceComparisonOptions: Array<string> = [
    this._equals,
    this._greaterThanEquals,
    this._greaterThan,
    this._lessThan,
    this._lessThanEquals,
  ];

  maximumTemperatureComparisonOptions: Array<string> = [
    this._greaterThanEquals,
    this._greaterThan,
    this._equals,
  ];

  minimumTemperatureComparisonOptions: Array<string> = [
    this._lessThanEquals,
    this._lessThan,
    this._equals,
  ];

  selectedNominalCorrosionAllowanceComparisonOption =
    this._defaultNominalCorrosionAllowanceComparisonSelectionString;
  selectedMaximumTemperatureComparisonOption =
    this._defualtMaximumTemperatureComparisonSelectionString;
  selectedMinimumTemperatureComparisonOption =
    this._defualtMinimumTemperatureComparisonSelectionString;

  selectedTemperatureUnit = this._fahrenheit;

  typeSelectedSubject = new BehaviorSubject<PipingObjectType>(this._both);
  typeSelected$ = this.typeSelectedSubject.asObservable();
  typeSelected: PipingObjectType;

  filtersForType$: Observable<PipingSearchFilterModel[]> =
    this.getFiltersForType();
  facetValues = new BehaviorSubject<PipingSearchFilterModel[]>([]);
  facetValues$ = this.facetValues.asObservable();
  latestFilterSelection$ = new BehaviorSubject<string>('');
  filters$: Observable<PipingSearchFilterModel[]> = this.getPipingFilters();
  pipeResults$: Observable<SearchResultObject[]>;
  filterableProperties: FilterableProperty[];
  filterablePropertiesFlattened: FilterableProperty[];
  pipeFilterCount: number;
  valveFilterCount: number;
  commonFilterCount: number;
  inputNumStr: string;
  pipe = new CelsiusToFahrenheitPipe();
  searchResultObjects: SearchResultObject[];

  //indicator subjects
  searching$ = new BehaviorSubject<boolean>(false);
  loadingFilters$ = new BehaviorSubject<boolean>(false);
  exportingPdf$ = new BehaviorSubject<boolean>(false);

  private readonly subscription = new Subscription();

  pageSizes = [2, 5, 10, 25, 50, 100];
  @ViewChild('paginator') paginator: MatPaginatorGoToComponent;
  @ViewChild('pipesCheckButton')
  pipesRadioButtonInput: ElementRef<HTMLInputElement>;
  @ViewChild('valvesCheckButton')
  valvesRadioButtonInput: ElementRef<HTMLInputElement>;
  @ViewChild('fahrenheitCheckButton')
  fahrenheitRadioButtonInput: ElementRef<HTMLInputElement>;
  @ViewChild('celsiusCheckButton')
  celsiusRadioButtonInput: ElementRef<HTMLInputElement>;

  filterDisplayArchivedItems = false;
  inProduction: boolean;

  ngOnInit() {
    this.inProduction = environment.production;

    this.subscription.add(
      this.filterDataService.currentFiltersSelection$
        .pipe(
          map((filterSelection) => {
            this.filterableProperties = Array.from(filterSelection.values());
            this.filterablePropertiesFlattened = this.filterableProperties;
            this.currentPage = 0;
            this.search();
          })
        )
        .subscribe()
    );

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.resetFilters();
  }

  getFiltersForType(): Observable<PipingSearchFilterModel[]> {
    return combineLatest([this.typeSelected$]).pipe(
      switchMap(([type]) => {
        this.typeSelected = type;
        return this.esraPipingAPIClient.getPdfPipingFiltersByObjectType(type).pipe(
          indicate(this.loadingFilters$),
          catchError(err => {
            console.log('Error from getFiltersByObjectType:', err);
            return throwError(() => new Error(err));
          }),
          tap((response) => {
            this.setFilterCounts(response);
          })
        );
      })
    );
  }

  callSearch(e) {
    if (e.keyCode == 13) this.search();
  }

  public search(): void {
    const request: SearchRequest = new SearchRequest();
    request.perPage = this.pageSize;
    request.page = this.currentPage;
    request.mainSearch = this.wrapSearchTextInWildcardsConditionally(this.searchText);
    request.filters = this.filterableProperties;
    request.orderBy = 'NameOrTag asc';

    this.technology = Number(TechnologyEnum.PIPES);
    this.subscription.add(
      this.esraPipingAPIClient
        .pdfSearchPipingAndValves(request)
        .pipe(
          indicate(this.searching$),
          tap((response) => {
            this.pipeResults$ = of(response.results);
            this.searchResultObjects = response.results;
            this.facetValues.next(response.searchFilters);
            this.totalCount = response.totalResults;
            this.lastPage = Math.ceil(this.totalCount / this.pageSize);
          })
        )
        .subscribe()
    );
  }

  public addSearchResultsToWorkspace() {
    const request: SearchRequest = new SearchRequest();
    request.perPage = this.INTMAXCSHARP;
    request.page = 0;
    request.mainSearch = this.searchText;
    request.filters = this.filterableProperties;

    this.subscription.add(
      this.esraPipingAPIClient
        .addSearchRequestPipeAndValveClassesToWorkspace(request)
        .pipe(
          indicate(this.searching$),
          tap({
            next: () => {
              this.snackBarService.showSnackBar(
                true,
                'All search results were added to your workspace'
              );
            },
            error: (err) => {
              this.snackBarService.showError(err.message);
            },
          })
        )
        .subscribe()
    );
  }

  private createFilterableProperty(
    filterType: FilterType,
    propertyName: string,
    filterDisplayName: string,
    filterOptions: string[]
  ): FilterableProperty {
    const filterableProperty = new FilterableProperty();
    filterableProperty.filterType = filterType;
    filterableProperty.propertyName = propertyName;
    filterableProperty.displayName = filterDisplayName;
    filterableProperty.filterOptions = filterOptions;
    return filterableProperty;
  }

  private setObjectTypeFilter() {
    if (this.typeSelected == this._pipe) {
      const pipeSet = new Set<string>(['PIPE']);
      this.updateCheckboxFilterSelection(pipeSet, 'ObjectType', 'ObjectType');
    } else if (this.typeSelected == this._valve) {
      const valveSet = new Set<string>(['VALVE']);
      this.updateCheckboxFilterSelection(valveSet, 'ObjectType', 'ObjectType');
    }
  }

  public updateCheckboxFilterSelection(
    event: Set<string>,
    filterablePropertyName: string,
    filterDisplayName: string
  ) {
    this.latestFilterSelection$.next(filterablePropertyName);
    this.currentPage = 0;
    if (event.size === 0) {
      this.isFilterBeingRemoved = true;
      this.filterDataService.removeAllFilterValues(filterablePropertyName);
    } else {
      this.isFilterBeingRemoved = false;
      const filterableProperty = this.createFilterableProperty(
        FilterType._1,
        filterablePropertyName,
        filterDisplayName,
        Array.from(event)
      );
      
      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  public notTrueFalseOptionOnly(filter: any): boolean {
    const optionsLowerCased = filter.options.map((filterOption: string) =>
      filterOption.toLowerCase()
    );
    return !(
      optionsLowerCased.length == 2 &&
      optionsLowerCased.includes('true') &&
      optionsLowerCased.includes('false')
    );
  }

  public updateAdditionalRadioFilterSelection(
    event: Set<string>,
    filterablePropertyName: string,
    filterDisplayName: string
  ) {
    console.log('updateAdditionalRadioFilterSelection() needs implemented');
  }

  public updateAdditionalTextFilterSelection(
     inputNumStr: string,
     filterablePropertyName: string,
     filterDisplayName: string,
     
  ) {
     this.currentPage = 0;
     
     if(inputNumStr === '') {
      this.filterDataService.removeAllFilterValues(filterablePropertyName);
     } else {
      const selectedOperatorString = this.getSelectedOperatorStringFromSymbol(this.selectedNominalCorrosionAllowanceComparisonOption);
      this.latestFilterSelection$.next(filterablePropertyName);

      const filterOptions = [selectedOperatorString + inputNumStr];

      const filterableProperty = this.createFilterableProperty(
        FilterType._7,
        filterablePropertyName,
        filterDisplayName,
        filterOptions
      );

      this.filterDataService.setFilterValue(filterableProperty);
      this.selectedNominalCorrosionAllowanceComparisonOption = '';
    }
  }

  public updateNumericalFilterSelection(
    event: string, 
    filterablePropertyName: string, 
    filterDisplayName: string, 
    selectedComparisonOperator: string
  ) {
    let selectedValue = event;

    if (selectedValue === '') {
      this.filterDataService.removeAllFilterValues(filterablePropertyName);
    } else {
      const selectedOperatorStr = this.getSelectedOperatorStringFromSymbol(selectedComparisonOperator);

      if (this.selectedTemperatureUnit === this._celsius) {
        selectedValue = this.pipe.transform(parseFloat(selectedValue));
      }

      this.latestFilterSelection$.next(filterablePropertyName);
      const filterableProperty = this.createFilterableProperty(
        FilterType._7,
        filterablePropertyName,
        filterDisplayName,
        [selectedOperatorStr + selectedValue]
      );

      this.filterDataService.setFilterValue(filterableProperty);
    }
  }
   
  public updateNominalCorrosionAllowanceComparisonOption(event: any) {
    this.selectedNominalCorrosionAllowanceComparisonOption = event;
  }

  public updateMaximumTemperatureComparisonOption(event: any) {
    this.selectedMaximumTemperatureComparisonOption = event;
  }

  public updateMinimumTemperatureComparisonOption(event: any) {
    this.selectedMinimumTemperatureComparisonOption = event;
  }

  public updateSingleSliderFilterSelection(
    event: string,
    filterablePropertyName: string,
    filterDisplayName: string,
    filterType: string
  ) {
    console.log('updateSingleSliderFilterSelection() needs implemented');
  }

  public updateRangeFilterSelection(
    event: string,
    filterablePropertyName: string,
    filterDisplayName: string,
    filterType: string
  ) {
    console.log('updateRangeFilterSelection() needs implemented');
  }

  public popClearedFilter($event: any, filterPropertyName: string) {
    if (this.latestFilterSelection$.getValue() === filterPropertyName) {
      this.latestFilterSelection$.next('');
    }
  }

  //This is the control point for if we want to count filters with empty options
  private setFilterCounts(pipingSearchFilterModels: PipingSearchFilterModel[]) {
    this.pipeFilterCount = pipingSearchFilterModels.filter(
      (filterModel) => filterModel.filterCategory === this._pipeFilter
    ).length;
    this.valveFilterCount = pipingSearchFilterModels.filter(
      (filterModel) => filterModel.filterCategory === this._valveFilter
    ).length;
    this.commonFilterCount = pipingSearchFilterModels.filter(
      (filterModel) => filterModel.filterCategory === this._commonFilter
    ).length;
  }

  private getPipingFilters(): Observable<PipingSearchFilterModel[]> {
    return combineLatest([
      this.facetValues$,
      this.filtersForType$,
      this.latestFilterSelection$,
    ]).pipe(
      map((values) => {
        const facets = values[0];
        const filters = values[1];
        const latestFilterSelection = values[2];
        
        filters.forEach((filter) => {
          if (this.isFilterBeingRemoved || filter.filterPropertyName != latestFilterSelection) {
            const matchingFacets = facets.filter(
              (facet) => facet.filterPropertyName === filter.filterPropertyName
            );
            if (matchingFacets[0] != null) {
              filter.options = matchingFacets[0].options;
              filter.minValue = matchingFacets[0].minValue;
              filter.maxValue = matchingFacets[0].maxValue;

              filter.options.sort((a, b) =>
                a.localeCompare(b, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              );
            }
          }
        });

        filters.sort((a, b) => a.displayOrder - b.displayOrder);
        this.setFilterCounts(filters);
        return filters;
      })
    );
  }

  public updateObjectTypeSelection(clickedElementId: string) {
    const valvesChecked: boolean =
      this.valvesRadioButtonInput.nativeElement.checked;
    const pipesChecked: boolean =
      this.pipesRadioButtonInput.nativeElement.checked;

    if (valvesChecked && pipesChecked) {
      this.filterDataService.removeAllFilters();
      this.typeSelectedSubject.next(this._both);
    } else if (valvesChecked && !pipesChecked) {
      this.typeSelectedSubject.next(this._valve);
    } else if (pipesChecked && !valvesChecked) {
      this.typeSelectedSubject.next(this._pipe);
    } else if (!valvesChecked && !pipesChecked) {
      if (clickedElementId == this._pipesCheckBoxId) {
        this.valvesRadioButtonInput.nativeElement.checked = true;
        this.typeSelectedSubject.next(this._valve);
      } else if (clickedElementId == this._valvesCheckBoxId) {
        this.pipesRadioButtonInput.nativeElement.checked = true;
        this.typeSelectedSubject.next(this._pipe);
      }
    }
    this.setObjectTypeFilter();
  }

  private getSelectedOperatorStringFromSymbol(symbol: string): string {
    if (symbol === '≥') {
      return 'ge '
    } else if (symbol === '>') {
      return 'gt ';
    } else if (symbol === '≤') {
      return 'le ';
    } else if (symbol === '<') {
      return 'lt ';
    } else {
      return 'eq ';
    }
  }

  //! this is the control point for the wildcard behavior
  private wrapSearchTextInWildcardsConditionally(searchText: string): string{
    let returnString = '';
    let notArray = searchText.split("+-");
    let andArray = searchText.split("+");

    if (notArray.length > 1){
      notArray = notArray.map((element) => element.trim());
      for (let i = 0; i < notArray.length; i ++){
        if (i !== notArray.length - 1){
          returnString = returnString + notArray[i] + "+-";
        } else {
          returnString = returnString + notArray[i];
        }
      }
    }
    else if (andArray.length > 1){
      andArray = andArray.map((element) => element.trim())
      for (let i = 0; i < andArray.length; i++){
        if (i !== andArray.length - 1){
          returnString = returnString + andArray[i] + "* + ";
        } else {
          returnString = returnString + andArray[i];
        }
      }
    }    
    else{
      //GETTING RID OF ALL ASTERISK AND ADDING ONE AT THE BOTTOM
      returnString = searchText.split('*').join('') + "*";
    }
    return returnString;
  }

  public clearSearchText() {
    this.searchText = '';
    this.search();
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.search();
  }

  resetPage() {
    this.pipesRadioButtonInput.nativeElement.checked = true;
    this.valvesRadioButtonInput.nativeElement.checked = true;
    this.typeSelectedSubject.next(this._both);
  }

  resetFilters() {
    this.resetPage();
    this.filterDataService.removeAllFilters();
  }

  exportAllToPdf() {
    alert('export all functionality not yet implemented');
  }

  public hideShowArchivedDraftChip(filterableProperties: FilterableProperty[]) {
    const allButArchivedDraftFilter = filterableProperties.filter(filter => filter.propertyName !== 'IsArchivedOrDraft');
    const archivedDraftFilter = filterableProperties.filter(filter => filter.propertyName === 'IsArchivedOrDraft')[0];

    if (archivedDraftFilter === undefined || archivedDraftFilter.filterOptions[0] === '1') {
      const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
        FilterType._6,
        'IsArchivedOrDraft',
        'Archived and Drafts'
      );
      // filterableProperty.filterOptions = ['Archived and Drafts']
      allButArchivedDraftFilter.push(filterableProperty);
    }
    
    return allButArchivedDraftFilter;
  }
}
