import { Component, Input, OnChanges } from '@angular/core';
import { ComponentsInPipeClassViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-pipe-component-details',
  templateUrl: './pipe-component-details.component.html',
  styleUrls: ['./pipe-component-details.component.scss']
})
export class PipeComponentDetailsComponent implements OnChanges{
  @Input()
  groupName: string;
  @Input()
  componentsInPipeClass: ComponentsInPipeClassViewModel[];
  
  expanded = true;

  ngOnChanges(): void {
    this.sortComponents();
  }
  
  sortComponents() {
    this.componentsInPipeClass.sort((a, b) => {
        if (a.component.type < b.component.type) {
            return -1;
        }

        if (a.component.type > b.component.type) {
            return 1;
        }
 
        return 0;
    });
  }

  getJoinedCommonNoteCodes(componentInPipeClass: ComponentsInPipeClassViewModel): string {
    if(componentInPipeClass.commonNotes && componentInPipeClass.commonNotes.length > 0) {
          return componentInPipeClass.commonNotes.map(commonNote => commonNote.codeId).join(", ");
    }
    return "";
  }
}
