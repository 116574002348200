import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BranchTableViewModel,
  EsraPipingAPIClient,
  PipeClassViewModel,
} from '../../shared/models/autogenerated-piping';
import { BehaviorSubject, Observable, tap, first } from 'rxjs';
import { DownloadService } from 'src/app/core/services/download.service';
import { indicate } from 'src/app/operators';
import { GraphService } from 'src/app/core/services/graph.service';
import { User } from '@microsoft/microsoft-graph-types';
import { environment } from 'src/environments/environment';
import { PipingAndValvesDocumentService } from '../../piping-valves/services/piping-and-valves-document.service';
import { InsightsService } from 'src/app/shared/services/insights.service';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-piping-tabs-parent-component',
  styleUrls: ['piping-tabs-parent.component.scss'],
  templateUrl: 'piping-tabs-parent.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PipingTabsParentComponent implements OnInit {
  @Input() pipeClassId: string;
  @Output() closePipingPage = new EventEmitter<boolean>();
  
  openState = true;
  pipeClassViewModel$: Observable<PipeClassViewModel>;
  pipeClassVM: PipeClassViewModel;
  serviceDisplayName = '';
  branchTableViewModelList: BranchTableViewModel[] = [];
  exportIndicator$ = new BehaviorSubject<boolean>(false);
  loadingIndicator$ = new BehaviorSubject<boolean>(false);
  graphService$ = new Observable<User>();
  userEmail = new BehaviorSubject<string>('');
  userEmail$ = this.userEmail.asObservable();
  isProduction = environment.production;
  faChevronLeft = faChevronLeft;

  constructor(
    private route: ActivatedRoute,
    private esraPipingAPIClient: EsraPipingAPIClient,
    private downloadService: DownloadService,
    private graphService: GraphService,
    private pipingAndValvesDocumentService: PipingAndValvesDocumentService,
    private insightsService: InsightsService,
    private location: LocationStrategy
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.closePipingPage.emit(false);
      indicate(this.loadingIndicator$)
    });
  }

  ngOnInit() {
    let initPipeClassId = '';

    const pipeClassName = this.route.snapshot.paramMap?.get('name');
    const businessUnit = this.route.snapshot.paramMap?.get('businessUnit');

    if (this.pipeClassId) {
      initPipeClassId = this.pipeClassId;
    }

    if (initPipeClassId) {
      this.getPipeClassById(initPipeClassId);
    }

    if (initPipeClassId || pipeClassName) {
      if (this.isProduction) {
        this.getPipeClassPDFs(initPipeClassId, pipeClassName, businessUnit);
      } else {
        this.getPipeClassDetails(initPipeClassId, pipeClassName, businessUnit);
      }
    }
  }

  private getPipeClassDetails(
    pipeClassId: string,
    pipeClassName: string,
    businessUnit: string
  ) {
    if (pipeClassId) {
      this.getPipeClassByName(pipeClassId, undefined);
    } else {
      this.getPipeClassByName(pipeClassName, businessUnit);
    }
  }

  private getPipeClassPDFs(
    pipeClassId: string,
    pipeClassName: string,
    businessUnit: string
  ) {
    if (pipeClassId) {
      this.getPDFByName(pipeClassId, 'CES');
    } else {
      this.getPDFByName(pipeClassName, businessUnit);
    }
  }

  private getPDFByName(pipeClassName: string, businessUnit: string) {
    this.pipingAndValvesDocumentService.openPDF(
      pipeClassName.toUpperCase(),
      businessUnit.toUpperCase(),
      false
    );
  }

  private getPipeClassByName(
    pipeClassName: string,
    businessUnit: string | undefined
  ): void {
    this.pipeClassViewModel$ = this.esraPipingAPIClient
      .getPipeClassByName(pipeClassName, businessUnit)
      .pipe(
        indicate(this.loadingIndicator$),
        first(),
        tap((pipeClass) => {
          this.loadPipeClass(pipeClass);
        })
      );
  }

  private getPipeClassById(pipeClassId: string): void {
    this.pipeClassViewModel$ = this.esraPipingAPIClient
      .getPipeClassByPipeClassId(pipeClassId)
      .pipe(
        indicate(this.loadingIndicator$),
        first(),
        tap((pipeClass) => {
          this.loadPipeClass(pipeClass);
        })
      );
  }

  private loadPipeClass(pipeClass: PipeClassViewModel) {
    this.branchTableViewModelList = pipeClass.branchTables;
    this.pipeClassVM = pipeClass;
  }

  exportToExcel(guid: string) {
    return this.downloadService.downloadFile(
      this.esraPipingAPIClient
        .exportPipeClassToExcel(guid)
        .pipe(indicate(this.exportIndicator$))
    );
  }

  exportToPdf(guid: string) {
    return this.downloadService.downloadFile(
      this.esraPipingAPIClient
        .exportPipeClassToPdf(guid)
        .pipe(indicate(this.exportIndicator$))
    );
  }

  getUserEmail(authorOid: string) {
    this.graphService$ = this.graphService.getUserByOid(authorOid);
    this.graphService$.subscribe((user) => {
      this.userEmail.next(user.mail);
    });
  }

  public onTabChange(event) {  
    if (event.tab.textLabel === 'branch table') {
      this.insightsService.logPageView('PipingTabsParentComponent branchTableTabClicked');
    }
  }

  closePipingSummaryPage(): void {
    this.closePipingPage.emit(false);
  }
}
