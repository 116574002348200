import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  SearchRequest,
  FilterableProperty,
} from 'src/app/shared/models/autogenerated-coating';
import {
  EsraPipingAPIClient,
  PipingSearchFilterModel,
  FilterType,
  SearchResultObject_1OfObject as SearchResultObject,
  PipingObjectType,
  PipingFilterCategory,
} from 'src/app/shared/models/autogenerated-piping';
import { TechnologyEnum } from 'src/app/shared/models/technology-enum';
import { PageEvent } from '@angular/material/paginator';
import { FilterDataService } from '../../services/filter.service';
import { RangeFilterHelper } from '../common/range-filter.helper';
import { PipingAndValvesNavigationService } from 'src/app/piping-valves/services/piping-and-valves-navigation.service';
import { CelsiusToFahrenheitPipe } from 'src/app/shared/pipes/celcius-to-fahrenheit.pipe';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { FilterablePropertyFactory } from 'src/app/shared/components/factory/filterable-property-factory.service';
import { MatPaginatorGoToComponent } from '../../../shared/components/mat-paginator-go-to/mat-paginator-go-to.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-piping-admin-search',
  templateUrl: './piping-admin-search.component.html',
  styleUrls: ['./piping-admin-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PipingAdminSearchComponent implements OnInit, OnDestroy {
  constructor(
    private esraPipingAPIClient: EsraPipingAPIClient,
    private filterDataService: FilterDataService,
    public rangeFilterHelper: RangeFilterHelper,
    public navigator: PipingAndValvesNavigationService,
    private snackBarService: SnackBarService,
    private cd: ChangeDetectorRef
  ) {}

  faSearch = faSearch;
  searchText = '';
  technology: TechnologyEnum = TechnologyEnum.PIPES;
  
  CSHARP_INT_MAX = 2147483647;

  lastPage: number;
  totalCount: number;
  currentPage = 0;
  pageSize = 10;
  pageSizes = [2, 5, 10, 25, 50, 100];

  // object types
  _both = PipingObjectType._0;
  _pipe = PipingObjectType._1;
  _valve = PipingObjectType._2;

  // filter categories
  _commonFilter = PipingFilterCategory._3;
  _pipeFilter = PipingFilterCategory._1;
  _valveFilter = PipingFilterCategory._2;

  // input ids
  _pipesCheckBoxId = 'pipes-check-button';
  _valvesCheckBoxId = 'valves-check-button';

  // temperature units
  _fahrenheit = 'fahrenheit';
  _celsius = 'celcius';

  // comparison units
  _equals = 'equals (=)';
  _greaterThan = 'greater than (>)';
  _greaterThanEquals = 'greater or equals (≥)';
  _lessThan = 'less than (<)';
  _lessThanEquals = 'less than or equals (≤)';

  // default step values for nominal corrosion allowance filter

  _nominalCorrosionAllowanceDefaultStepValue = 0.000;
  _nominalCorrosionAllowanceStep = 0.0625;

  _defaultNominalCorrosionAllowanceComparisonSelectionString = this._equals;
  _defaultMaximumTemperatureComparisonSelectionString = this._greaterThanEquals;
  _defaultMinimumTemperatureComparisonSelectionString = this._lessThanEquals;
  nominalCorrosionAllowanceComparisonOptions: Array<string> = [
    this._equals,
    this._greaterThanEquals,
    this._greaterThan,
    this._lessThan,
    this._lessThanEquals,
  ];

  maximumTemperatureComparisonOptions: Array<string> = [
    this._greaterThanEquals,
    this._greaterThan,
    this._equals,
  ];

  minimumTemperatureComparisonOptions: Array<string> = [
    this._lessThanEquals,
    this._lessThan,
    this._equals,
  ];

  selectedNominalCorrosionAllowanceComparisonOption =
    this._defaultNominalCorrosionAllowanceComparisonSelectionString;
  selectedMaximumTemperatureComparisonOption =
    this._defaultMaximumTemperatureComparisonSelectionString;
  selectedMinimumTemperatureComparisonOption =
    this._defaultMinimumTemperatureComparisonSelectionString;

  selectedTemperatureUnit = this._fahrenheit;

  typeSelectedSubject = new BehaviorSubject<PipingObjectType>(this._both);
  typeSelected$ = this.typeSelectedSubject.asObservable();
  typeSelected: PipingObjectType = this._both;
  
  newFilters: PipingSearchFilterModel[] = [];
  originalFilters: PipingSearchFilterModel[] = [];
  isOriginalFiltersSet = false;
  latestFilterSelection = '';

  pipeResults$: Observable<SearchResultObject[]>;
  filterableProperties: FilterableProperty[];
  filterablePropertiesFlattened: FilterableProperty[];

  pipeFilterCount: number;
  valveFilterCount: number;
  commonFilterCount: number;

  inputNumStr: string;
  pipe = new CelsiusToFahrenheitPipe();
  searchResultObjects: SearchResultObject[];

  inProduction: boolean;
  filterDisplayArchivedItems = false;
  isFilterBeingRemoved: boolean;

  valveId: string;
  valveSelected: boolean = false;
  pipeClassId: string;
  pipeClassSelected: boolean = false;

  //indicator subjects
  searching$ = new BehaviorSubject<boolean>(false);
  loadingFilters$ = new BehaviorSubject<boolean>(false);
  exportingPdf$ = new BehaviorSubject<boolean>(false);

  private readonly subscription = new Subscription();

  @ViewChild('paginator') paginator: MatPaginatorGoToComponent;
  @ViewChild('pipesCheckButton')
  pipesRadioButtonInput: ElementRef<HTMLInputElement>;
  @ViewChild('valvesCheckButton')
  valvesRadioButtonInput: ElementRef<HTMLInputElement>;
  @ViewChild('fahrenheitCheckButton')
  fahrenheitRadioButtonInput: ElementRef<HTMLInputElement>;
  @ViewChild('celsiusCheckButton')
  celsiusRadioButtonInput: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.subscription.add(
      this.filterDataService.currentFiltersSelection$
        .pipe(
          map((filterSelection) => {
            this.filterableProperties = Array.from(filterSelection.values());
            this.filterablePropertiesFlattened = this.filterableProperties;
            this.currentPage = 0;
            this.search();
          })
        )
        .subscribe()
    );
    this.inProduction = environment.production;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.resetFilters();
  }

  callSearch(e) {
    if (e.keyCode == 13) this.search();
  }

  public search(): void {
    const request: SearchRequest = new SearchRequest({
      page: this.currentPage,
      perPage: this.pageSize,
      mainSearch: this.wrapSearchTextInWildcardsConditionally(
        this.searchText
      ),
      filters: this.filterableProperties
    });

    this.technology = Number(TechnologyEnum.PIPES);
    this.subscription.add(
      this.esraPipingAPIClient
        .searchPipingAndValves(request)
        .pipe(
          indicate(this.searching$),
          tap((response) => {
            this.setFilters(response);
            this.pipeResults$ = of(response.results);
            this.searchResultObjects = response.results;
            this.totalCount = response.totalResults;
            this.lastPage = Math.ceil(this.totalCount / this.pageSize);
          })
        )
        .subscribe()
    );
  }

  setFilters(response): void {
    if (!this.isOriginalFiltersSet) {
      // During the page's initialization, originalFilters is populated.
      this.originalFilters = this.sortFilters(response.pipingSearchFilters);
      this.isOriginalFiltersSet = true;
    } else {
      /*
        Upon every subsequent search call, every filter receives the filter
        options returned by the most recent search call, except for the 
        last touched filter.

        This enables users to receive a reduced set of filter options in 
        other categories.
      */
      this.newFilters = response.pipingSearchFilters;
      this.originalFilters.forEach((originalFilter) => {
        if (this.latestFilterSelection !== originalFilter.filterPropertyName || this.isFilterBeingRemoved) {
          this.newFilters.forEach((newFilter) => {
            if (newFilter.filterDisplayName === originalFilter.filterDisplayName) {
              originalFilter.options = newFilter.options;
            }
          });
        }
      });
    }
    this.setFilterCounts(this.originalFilters);
    this.sortFilters(this.originalFilters);

  }

public sortFilters(filters: PipingSearchFilterModel[]): PipingSearchFilterModel[] {
  const newFilter = filters.filter(
    (filter) => filter.options && filter.options.length > 0
  );
  newFilter.forEach((filter) => {
    filter.options.sort((a, b) => a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: 'base',})
    );
  });
  newFilter.sort((a,b) => (a.displayOrder - b.displayOrder));
  
  return newFilter;
}

  public addSearchResultsToWorkspace() {
    const request: SearchRequest = new SearchRequest();
    request.perPage = this.CSHARP_INT_MAX;
    request.page = 0;
    request.mainSearch = this.searchText;
    request.filters = this.filterableProperties;

    this.subscription.add(
      this.esraPipingAPIClient
        .addSearchRequestPipeAndValveClassesToWorkspace(request)
        .pipe(
          indicate(this.searching$),
          tap({
            next: () => {
              this.snackBarService.showSnackBar(
                true,
                'All search results were added to your workspace'
              );
            },
            error: (err) => {
              this.snackBarService.showError(err.message);
            },
          })
        )
        .subscribe()
    );
  }

  private createFilterableProperty(
    filterType: FilterType,
    propertyName: string,
    filterDisplayName: string,
    filterOptions: string[]
  ): FilterableProperty {
    const filterableProperty = new FilterableProperty();
    filterableProperty.filterType = filterType;
    filterableProperty.propertyName = propertyName;
    filterableProperty.displayName = filterDisplayName;
    filterableProperty.filterOptions = filterOptions;
    return filterableProperty;
  }

  private setObjectTypeFilter() {
    const typeSelected = this.typeSelectedSubject.value;
   if (typeSelected === this._valve) {
    const valveSet = new Set<string>(['VALVE']);
    this.updateCheckboxFilterSelection(valveSet, 'ObjectType', 'ObjectType');
   } else if (typeSelected === this._pipe) {
    const pipeSet = new Set<string>(['PIPE']);
    this.updateCheckboxFilterSelection(pipeSet, 'ObjectType', 'ObjectType');
  }
}

  public updateCheckboxFilterSelection(
    event: Set<string>,
    filterablePropertyName: string,
    filterDisplayName: string
  ) {
    this.latestFilterSelection = filterablePropertyName;
    this.currentPage = 0;

    if (event.size === 0) {
      this.isFilterBeingRemoved = true;
    } else {
      this.isFilterBeingRemoved = false;
    }

    if (this.isFilterBeingRemoved) {
      this.filterDataService.removeAllFilterValues(filterablePropertyName);
    } else {
      const filterableProperty = this.createFilterableProperty(
        FilterType._1,
        filterablePropertyName,
        filterDisplayName,
        Array.from(event)
      );
      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  public notTrueFalseOptionOnly(filter): boolean {
    const optionsLowerCased = filter.options.map((filterOption: string) =>
      filterOption.toLowerCase()
    );
    return !(
      optionsLowerCased.length == 2 &&
      optionsLowerCased.includes('true') &&
      optionsLowerCased.includes('false')
    );
  }

  public updateAdditionalTextFilterSelection(
    inputNumStr: string,
    filterablePropertyName: string,
    filterDisplayName: string
  ) {
    this.currentPage = 0;

    if (inputNumStr === '' || inputNumStr === null) {
      this.filterDataService.removeAllFilterValues(filterablePropertyName);
    } else {
      const selectedOperatorString = this.getSelectedOperatorStringFromSelection(
        this.selectedNominalCorrosionAllowanceComparisonOption
      );
      this.latestFilterSelection = filterablePropertyName;

      const filterOptions = [selectedOperatorString + inputNumStr];

      const filterableProperty = this.createFilterableProperty(
        FilterType._7,
        filterablePropertyName,
        filterDisplayName,
        filterOptions
      );

      this.filterDataService.setFilterValue(filterableProperty);
      this.selectedNominalCorrosionAllowanceComparisonOption = '';
    }
  }

  public updateNumericalFilterSelection(
    event: string,
    filterablePropertyName: string,
    filterDisplayName: string,
    selectedComparisonOperator: string
  ) {
    let selectedValue = event;
    if (selectedValue === '') {
      this.filterDataService.removeAllFilterValues(filterablePropertyName);
    } else {
      const selectedOperatorStr = this.getSelectedOperatorStringFromSelection(
        selectedComparisonOperator
      );

      if (this.selectedTemperatureUnit === this._celsius) {
        selectedValue = this.pipe.transform(parseFloat(selectedValue));
      }

      this.latestFilterSelection = filterablePropertyName;
      const filterableProperty = this.createFilterableProperty(
        FilterType._7,
        filterablePropertyName,
        filterDisplayName,
        [selectedOperatorStr + selectedValue]
      );

      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  public updateNominalCorrosionAllowanceComparisonOption(event) {
    this.selectedNominalCorrosionAllowanceComparisonOption = event;
  }

  public updateMaximumTemperatureComparisonOption(event) {
    this.selectedMaximumTemperatureComparisonOption = event;
  }

  public updateMinimumTemperatureComparisonOption(event) {
    this.selectedMinimumTemperatureComparisonOption = event;
  }

  public popClearedFilter(filterPropertyName: string) {
    if (this.latestFilterSelection === filterPropertyName) {
      this.latestFilterSelection = '';
    }
  }

  //This is the control point for if we want to count filters with empty options
  private setFilterCounts(pipingSearchFilterModels: PipingSearchFilterModel[]) {
    this.pipeFilterCount = pipingSearchFilterModels.filter(
      (filterModel) => filterModel.filterCategory === this._pipeFilter && (!filterModel.options || filterModel.options.length > 0)
    ).length;
    this.valveFilterCount = pipingSearchFilterModels.filter(
      (filterModel) => filterModel.filterCategory === this._valveFilter  && (!filterModel.options || filterModel.options.length > 0)
    ).length;
    this.commonFilterCount = pipingSearchFilterModels.filter(
      (filterModel) => filterModel.filterCategory === this._commonFilter  && (!filterModel.options || filterModel.options.length === 0)
    ).length;
  }

  public updateObjectTypeSelection(clickedElementId: string) {
    const valvesChecked: boolean =
      this.valvesRadioButtonInput.nativeElement.checked;
    const pipesChecked: boolean =
      this.pipesRadioButtonInput.nativeElement.checked;

    if (valvesChecked && pipesChecked) {
      this.filterDataService.removeAllFilters();
      this.typeSelectedSubject.next(this._both);
    } else if (valvesChecked && !pipesChecked) {
      this.typeSelectedSubject.next(this._valve);
    } else if (pipesChecked && !valvesChecked) {
      this.typeSelectedSubject.next(this._pipe);
    } else if (!valvesChecked && !pipesChecked) {
      if (clickedElementId == this._pipesCheckBoxId) {
        this.valvesRadioButtonInput.nativeElement.checked = true;
        this.typeSelectedSubject.next(this._valve);
      } else if (clickedElementId == this._valvesCheckBoxId) {
        this.pipesRadioButtonInput.nativeElement.checked = true;
        this.typeSelectedSubject.next(this._pipe);
      }
    } 
     this.setObjectTypeFilter();
  }

  private getSelectedOperatorStringFromSelection(userSelection: string): string {
    switch (userSelection) {
      case (this._equals):
        return 'eq ';
      case (this._greaterThan):
        return 'gt ';
      case (this._greaterThanEquals):
        return 'ge ';
      case (this._lessThan):
        return 'lt ';
      case (this._lessThanEquals):
        return 'le '
      default:
        return 'Error: user selection is not valid';
    }
  }

  // this is the control point for the wildcard behavior
  private wrapSearchTextInWildcardsConditionally(searchText: string): string {
    let returnString = '';
    let notArray = searchText.split('+-');
    let andArray = searchText.split('+');

    if (notArray.length > 1) {
      notArray = notArray.map((element) => element.trim());
      for (let i = 0; i < notArray.length; i++) {
        if (i !== notArray.length - 1) {
          returnString = returnString + notArray[i] + '+-';
        } else {
          returnString = returnString + notArray[i];
        }
      }
    } else if (andArray.length > 1) {
      andArray = andArray.map((element) => element.trim());
      for (let i = 0; i < andArray.length; i++) {
        if (i !== andArray.length - 1) {
          returnString = returnString + andArray[i] + '* + ';
        } else {
          returnString = returnString + andArray[i];
        }
      }
    } else {
      //GETTING RID OF ALL ASTERISK AND ADDING ONE AT THE BOTTOM
      returnString = searchText.split('*').join('') + "*";
    }
    return returnString;
  }

  public clearSearchText() {
    this.searchText = '';
    this.search();
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.search();
  }

  resetPage() {
    this.pipesRadioButtonInput.nativeElement.checked = true;
    this.valvesRadioButtonInput.nativeElement.checked = true;
    this.typeSelectedSubject.next(this._both);
  }

  resetFilters() {
    this.resetPage();
    this.filterDataService.removeAllFilters();
  }

  exportAllToPdf() {
    alert('export all functionality not yet implemented');
  }

  removedChip(propertyName, option) {
    const filterableProperties = this.filterableProperties.filter(property => property.propertyName == propertyName);
    if(filterableProperties && filterableProperties.length === 1) {
      const filterableProperty = filterableProperties[0];
      filterableProperty.filterOptions = filterableProperty.filterOptions.filter(filterOption => filterOption !== option);
      this.filterDataService.removeSingleFilterValue(propertyName, option)
    }
  }

  public hideShowArchivedDraftChip(filterableProperties: FilterableProperty[]) {
    const allButArchivedDraftFilter = filterableProperties.filter(
      (filter) => filter.propertyName !== 'IsArchivedOrDraft'
    );
    const archivedDraftFilter = filterableProperties.filter(
      (filter) => filter.propertyName === 'IsArchivedOrDraft'
    )[0];

    if (
      archivedDraftFilter === undefined ||
      archivedDraftFilter.filterOptions[0] === '1'
    ) {
      const filterableProperty =
        FilterablePropertyFactory.createFilterbleProperty(
          FilterType._6,
          'IsArchivedOrDraft',
          'Archived and Drafts'
        );
      allButArchivedDraftFilter.push(filterableProperty);
    }

    return allButArchivedDraftFilter;
  }

  setValveSelected(valveSelected: boolean): void {
    this.valveSelected = valveSelected;
  }

  setPipeClassSelected(pipeClassSelected: boolean): void {
    this.pipeClassSelected = pipeClassSelected;
  }

  setValveId(valveId: string): void {
    this.valveId = valveId;
  }

  setPipeClassId(pipeClassId: string): void {
    this.pipeClassId = pipeClassId;
  }

  convertAbbreviationIntoSymbol(chipString: string): string {
    if (chipString.includes('eq')) {
      chipString = chipString.replace('eq', '=');
    } else if (chipString.includes('gt')) {
      chipString = chipString.replace('gt', '>');
    } else if (chipString.includes('ge')) {
      chipString = chipString.replace('ge', '≥'); 
    } else if (chipString.includes('lt')) {
      chipString = chipString.replace('lt', '<');
    } else if (chipString.includes('le')) {
      chipString = chipString.replace('le', '≤');
    }

    return chipString;
  }
}
